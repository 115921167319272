$gradients: (
  "indigo":     (start: $pgIndigoGradS,       end: $pgIndigoGradE),
  "blue":       (start: $pgBlueGradS,         end: $pgBlueGradE),
  "green":      (start: $pgGreenGradS,        end: $pgGreenGradE),
  "dark-green": (start: $pgDarkGreenGradStart, end: $pgDarkGreenGradEnd),
  "yellow":     (start: $pgYellowGradS,       end: $pgYellowGradE),
  "orange":     (start: $pgOrangeGradS,       end: $pgOrangeGradE),
  "red":        (start: $pgRedGradS,          end: $pgRedGradE),
  "maroon":     (start: $pgMaroonGradS,       end: $pgMaroonGradE),
  "purple":     (start: $pgPurpleGradS,       end: $pgPurpleGradE),
  "dark":       (start: $pgDarkGradS,         end: $pgDarkGradE),
  "light":      (start: $pgLightGradS,        end: $pgLightGradE)
);

@each $name, $colors in $gradients {
  .bg-gradient-pg-#{$name} {
    background-color: map-get($colors, start);
    background-image: linear-gradient(135deg,
      map-get($colors, start) 0%,
      fade-out(map-get($colors, end), 0.2) 100%
    );
  }
}
